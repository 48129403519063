import React from 'react';

import type { GatsbyBrowser } from 'gatsby';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AnalyticsProvider } from '@web/analytics';
import { Cookies } from '@web/utils';

import { BusinessConfigurationProvider } from './src/hooks/business-configuration';
import { LocaleProvider } from './src/i18n/locale-provider';
import { commonEventProperties, postHogConfig, segmentConfig } from './src/lib/analytics-config';
import { siteConfig } from './src/lib/config';
import './src/styles/global.css';
import type { PageContext } from './src/types';

Cookies.saveParamsToCookies();

window.gtag = function gtag() {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BusinessConfigurationProvider>{element}</BusinessConfigurationProvider>
    </QueryClientProvider>
  );
};

export const wrapPageElement: GatsbyBrowser<any, PageContext>['wrapPageElement'] = ({ element, props }) => {
  return (
    <LocaleProvider initialLocale={props.pageContext.locale}>
      <AnalyticsProvider
        commonEventProperties={commonEventProperties}
        commonEventPropertyProviders={{ language: () => Cookies.get('freska_locale') ?? siteConfig.defaultLocale }}
        postHogConfig={postHogConfig}
        segmentConfig={segmentConfig}
      >
        {element}
      </AnalyticsProvider>
    </LocaleProvider>
  );
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  // Remove this when we get rid of Giosg
  window.FRESKA_PATHNAME = location.pathname;

  // Refresh HubSpot widget
  window.HubSpotConversations?.widget.refresh();
};
