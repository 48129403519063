import { CountryCode, Frequency } from '@freskanet/constants';

const PRICE_MATRIX: Record<string, Record<Frequency, Record<number, number>>> = {
  [CountryCode.FI]: {
    [Frequency.ONCE]: { 2: 69.9, 3: 69.9, 4: 67.9, 5: 65.9, 6: 64.9, 7: 63.9, 8: 62.9 },
    [Frequency.WEEKLY]: { 2: 51.9, 3: 50.9, 4: 48.9, 5: 47.9, 6: 46.9, 7: 45.9, 8: 44.9 },
    [Frequency.BIWEEKLY]: { 2: 58.9, 3: 56.9, 4: 54.9, 5: 52.9, 6: 51.9, 7: 50.9, 8: 49.9 },
    [Frequency.EVERY_FOUR_WEEKS]: { 2: 62.9, 3: 60.9, 4: 58.9, 5: 56.9, 6: 54.9, 7: 53.9, 8: 52.9 },
  },
  [CountryCode.SE]: {
    [Frequency.ONCE]: { 2: 858, 3: 858, 4: 828, 5: 798, 6: 768, 7: 768, 8: 768 },
    [Frequency.WEEKLY]: { 2: 598, 3: 578, 4: 548, 5: 528, 6: 518, 7: 508, 8: 498 },
    [Frequency.BIWEEKLY]: { 2: 658, 3: 638, 4: 598, 5: 568, 6: 548, 7: 528, 8: 508 },
    [Frequency.EVERY_FOUR_WEEKS]: { 2: 698, 3: 678, 4: 638, 5: 608, 6: 578, 7: 548, 8: 518 },
  },
  [CountryCode.NO]: {
    [Frequency.ONCE]: { 2: 699, 3: 699, 4: 679, 5: 659, 6: 649, 7: 639, 8: 639 },
    [Frequency.WEEKLY]: { 2: 669, 3: 629, 4: 599, 5: 569, 6: 539, 7: 529, 8: 529 },
    [Frequency.BIWEEKLY]: { 2: 689, 3: 649, 4: 589, 5: 579, 6: 569, 7: 549, 8: 549 },
    [Frequency.EVERY_FOUR_WEEKS]: { 2: 709, 3: 669, 4: 609, 5: 599, 6: 589, 7: 569, 8: 569 },
  },
} as const;

export const getMatrixHourlyPrice = (countryCode: CountryCode, frequency: Frequency, duration: number) => {
  return PRICE_MATRIX[countryCode][frequency][Math.floor(duration)];
};
