import React, { createContext, useCallback, useEffect, useState } from 'react';

import type { PostHog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import type { AnalyticsBrowser } from '@segment/analytics-next';

import type { AnalyticsEventMap } from '../types';
import type { IdentifyUserId, IdentifyUserTraits } from '../types/identify';
import type { CommonEventProperties, CommonEventPropertyProviders } from '../utils/common-properties';
import { buildCommonEventProperties, filterCommonEventPropertiesForPostHog } from '../utils/common-properties';
import type { ConsentChoice } from '../utils/consent-choice';
import { getStoredConsentChoice } from '../utils/consent-choice';
import { configurePostHogConsentChoice, initPostHog, type PostHogConfig } from '../utils/posthog';
import type { SegmentConfig } from '../utils/segment';
import { buildSegmentOptions, initSegment, loadSegment } from '../utils/segment';
import { flattenUserTraits, getUserTraits, updateUserTraits } from '../utils/user-traits';

export type AnalyticsContextInterface = {
  identify: (userId?: IdentifyUserId, userTraits?: IdentifyUserTraits) => void;
  track: <T extends keyof AnalyticsEventMap>(
    event: T,
    data: AnalyticsEventMap[T],
    userTraits?: IdentifyUserTraits,
  ) => void;
  trackPage: () => void;
  setConsentChoice: (consentChoice: ConsentChoice) => void;
  postHogClient: PostHog | undefined;
};

export const AnalyticsContext = createContext<AnalyticsContextInterface | undefined>(undefined);

export type AnalyticsProviderProps = {
  autoLoadWithConsentChoice?: ConsentChoice;
  children?: React.ReactNode;
  commonEventProperties: CommonEventProperties;
  commonEventPropertyProviders?: Partial<CommonEventPropertyProviders>;

  /**
   * Disables the identify calls for analytics clients, should be user for example when the user is known to be
   * an internal user and user traits they might generate should not be linked to individual users.
   */
  disableIdentify?: boolean;
  enabled?: boolean;
  enablePostHogDebug?: boolean;
  enableSegmentDebug?: boolean;
  postHogConfig?: PostHogConfig;
  segmentConfig?: SegmentConfig;
};

export const AnalyticsProvider = ({
  autoLoadWithConsentChoice,
  children,
  commonEventProperties,
  commonEventPropertyProviders,
  disableIdentify = false,
  enabled = true,
  enablePostHogDebug = false,
  enableSegmentDebug = false,
  postHogConfig,
  segmentConfig,
}: AnalyticsProviderProps) => {
  // Initial consent choice will respect the autoLoadWithConsentChoice prop if set, otherwise it will
  // try to load the stored consent choice from the cookies.
  const [initialConsentChoice] = useState<ConsentChoice | null>(
    () => autoLoadWithConsentChoice ?? getStoredConsentChoice(),
  );

  const [analyticsClient, setAnalyticsClient] = useState<AnalyticsBrowser | undefined>(undefined);
  const [postHogClient, setPosthogClient] = useState<PostHog | undefined>(undefined);

  // Initialize analytics clients when the component is mounted and the analytics is enabled; do it
  // only after that to avoid SSR hydration mismatch errors.
  useEffect(() => {
    if (enabled && segmentConfig && !analyticsClient) {
      const newSegmentClient = initSegment();
      if (enableSegmentDebug) newSegmentClient?.debug(enableSegmentDebug);
      setAnalyticsClient(newSegmentClient);

      // Load analytics when the consent choice is already set on initial AnalyticsProvider mount.
      // This is used for example in checkout when the user is in sales checkout and no consent will
      // separately be asked.
      if (initialConsentChoice) {
        loadSegment({ ...segmentConfig, consentChoice: initialConsentChoice });
      }
    }

    if (enabled && postHogConfig && !postHogClient) {
      const newClient = initPostHog({
        config: postHogConfig,
        consentChoice: initialConsentChoice,
        commonEventProperties,
      });
      if (enablePostHogDebug) newClient?.debug(enablePostHogDebug);
      setPosthogClient(newClient);
    }
  }, [
    enabled,
    initialConsentChoice,
    analyticsClient,
    segmentConfig,
    postHogClient,
    commonEventProperties,
    enableSegmentDebug,
    postHogConfig,
    enablePostHogDebug,
  ]);

  // Callback to load analytics with the given consent choice, this should be triggered
  // when the user selects or changes their consent choice to load analytics with the
  // new consent choice.
  const setConsentChoice = useCallback(
    (consentChoice: ConsentChoice) => {
      if (!enabled) return;

      if (analyticsClient && segmentConfig) {
        loadSegment({ ...segmentConfig, consentChoice });
      }

      if (postHogClient) configurePostHogConsentChoice(postHogClient, consentChoice);
    },
    [enabled, analyticsClient, segmentConfig, postHogClient],
  );

  const identify = useCallback(
    (userId?: IdentifyUserId, userTraitsUpdate?: IdentifyUserTraits) => {
      // Ensure the traits are always updated and stored for the following track calls, but we don't need
      // all of the user traits to be sent on this identify call, only the updated ones.
      if (userTraitsUpdate) updateUserTraits(userTraitsUpdate);

      if (!enabled || disableIdentify) return;

      if (analyticsClient) {
        const options = buildSegmentOptions({ commonEventProperties });
        analyticsClient.identify(userId, userTraitsUpdate, options);
      }

      if (postHogClient) {
        const postHogUserTraits = flattenUserTraits(userTraitsUpdate);

        // Only identify the user if the user id is provided, otherwise just set the traits for the current user
        if (userId) {
          postHogClient.identify(userId, postHogUserTraits);
        } else {
          postHogClient.capture('$set', { $set: postHogUserTraits });
        }
      }
    },
    [enabled, disableIdentify, analyticsClient, postHogClient, commonEventProperties],
  );

  const track = useCallback(
    async <T extends keyof AnalyticsEventMap>(
      event: T,
      data: AnalyticsEventMap[T],
      newUserTraits?: IdentifyUserTraits,
    ) => {
      // Ensure the traits are always updated and stored for the following track calls and use the complete
      // set of traits for the current track call for Segment.
      const segmentUserTraits = updateUserTraits(newUserTraits);

      if (!enabled) return;

      const builtCommonProperties = buildCommonEventProperties(commonEventPropertyProviders);

      if (analyticsClient) {
        const properties = { ...builtCommonProperties, ...data };
        const options = buildSegmentOptions({ commonEventProperties, userTraits: segmentUserTraits });

        analyticsClient.track(event, properties, options);

        // For lead form submissions, we need to send a separate event through Segment that enabled
        // us to track these submissions separately from other form submissions in device mode destinations
        // such as Google Ads, that can only be configured to track specific events, not their properties.
        if (event === 'Form Submitted' && (data as AnalyticsEventMap['Form Submitted']).formObjective === 'lead') {
          analyticsClient.track('Lead Form Submitted', properties, options);
        }
      }

      if (postHogClient) {
        // For PostHog we don't need to send all user traits, only the newly set ones
        const postHogUserTraits = flattenUserTraits(newUserTraits);

        postHogClient.capture(event, {
          ...commonEventProperties,
          ...filterCommonEventPropertiesForPostHog(builtCommonProperties),
          ...data,
          // Set user properties for the event
          $set: postHogUserTraits,
        });
      }
    },
    [commonEventProperties, commonEventPropertyProviders, enabled, analyticsClient, postHogClient],
  );

  const trackPage = useCallback(() => {
    if (!enabled) return;

    const commonProperties = buildCommonEventProperties(commonEventPropertyProviders);
    const properties = { ...commonEventProperties, ...commonProperties };

    if (analyticsClient) {
      const options = buildSegmentOptions({ commonEventProperties, userTraits: getUserTraits() });
      analyticsClient.page(undefined, undefined, properties, options);
    }

    if (postHogClient) postHogClient.capture('$pageview', filterCommonEventPropertiesForPostHog(properties));
  }, [enabled, commonEventProperties, commonEventPropertyProviders, analyticsClient, postHogClient]);

  return (
    <PostHogProvider client={postHogClient}>
      <AnalyticsContext.Provider value={{ identify, track, trackPage, setConsentChoice, postHogClient }}>
        {children}
      </AnalyticsContext.Provider>
    </PostHogProvider>
  );
};
