import React from 'react';

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const RadioUncheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0,0h24v24H0V0z" />
      <path fill="currentColor" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z" />
      <circle fill="#ffffff" cx="12" cy="12" r="8" />
    </SvgIcon>
  );
};
