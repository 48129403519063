import { isNil, omit, omitBy } from 'lodash';

import { Storage } from '@web/utils';

import type { IdentifyUserTraits } from '../types/identify';

import { getIsFunctionalStorageAllowed } from './consent-choice';

// For PostHog, we need to flatten the address object to ensure we don't override
// the entire nested object, but only the changed fields.
export const flattenUserTraits = (traits?: IdentifyUserTraits) => {
  const addressTraits = Object.entries(traits?.address ?? {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`address.${key}`]: value,
    }),
    {},
  );

  return {
    ...omit(traits, 'address'),
    ...addressTraits,
  };
};

const mergeUserTraits = (
  oldTraits: IdentifyUserTraits | undefined,
  newTraits: IdentifyUserTraits | undefined,
): IdentifyUserTraits => {
  return {
    ...oldTraits,
    ...omitBy(newTraits, (v) => isNil(v) || v === ''),
    address: {
      ...oldTraits?.address,
      ...omitBy(newTraits?.address, (v) => isNil(v) || v === ''),
    },
  };
};

// In memory store for user traits
let userTraits: IdentifyUserTraits = {};

/**
 * Get the user traits from the storage respecting the consent choice.
 */
export const getUserTraits = (): IdentifyUserTraits => {
  // Only return the in-memory user traits if the storage is not allowed
  if (!getIsFunctionalStorageAllowed()) return userTraits;

  // Catch any issues on local storage or if the traits are corrupted
  try {
    const storedTraits = Storage.get('freska_user_traits', false);
    const parsedTraits = storedTraits ? JSON.parse(storedTraits) : {};

    // Ensure the parsed user traits is an object
    if (typeof parsedTraits === 'object' && parsedTraits !== null) {
      userTraits = parsedTraits;
    }
  } catch (err) {
    // NOOP in case the stored traits are corrupted, not JSON etc
  }

  return userTraits;
};

/**
 * Add user traits to currently stored traits and store them in the storage if consent choice allows.
 */
export const updateUserTraits = (newUserTraits?: IdentifyUserTraits): IdentifyUserTraits => {
  const storedTraits = getUserTraits();
  if (!newUserTraits || Object.keys(newUserTraits).length === 0) return storedTraits;

  userTraits = mergeUserTraits(storedTraits, newUserTraits);

  // Store the user traits in the storage if consent choice allows
  if (getIsFunctionalStorageAllowed()) {
    Storage.setSiteWide('freska_user_traits', JSON.stringify(userTraits));
  }

  return userTraits;
};
