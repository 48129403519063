import queryString from 'query-string';
import UniversalCookie from 'universal-cookie';

const EXPIRATION_DAYS = 20;

const cookies = new UniversalCookie();
const getTopDomain = () => window.location.hostname.replace(/^[a-zA-Z0-9]*\./, '.');

export type FreskaCookie =
  | 'freska_anonymous_id'
  | 'freska_apartment_size'
  | 'freska_locale'
  | 'freska_consent_choice'
  | 'freska_features'
  | 'freska_session_start'
  | 'freska_order_completed_event_sent_id'
  | 'freska_privacy_policy_accepted'
  | 'freska_home_cleaning_service_worker_uuid'
  | 'freska_home_cleaning_start_time_id'
  | 'freska_moving_cleaning_service_worker_uuid'
  | 'freska_moving_cleaning_start_time_id'
  | 'freska_moving_cleaning_terms_accepted'
  | 'freska_window_cleaning_service_worker_uuid'
  | 'freska_window_cleaning_start_time_id'
  | 'freska_window_cleaning_terms_accepted'
  | 'freska_postcode'
  | 'freska_service_worker_uuid'
  | 'freska_top_banner'
  | 'freska_customer_token'
  | 'freska_user_traits'
  | 'coupon-code' // FIXME: cookie name
  // 3rd party cookies
  | '_fbc'
  | '_fbp'
  | '_gcl_aw'
  | 'ajs_anonymous_id'
  | 'hubspotutk';

const dateFromToday = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export class Cookies {
  static get(name: FreskaCookie, doNotParse: boolean = false) {
    return cookies.get(name, { doNotParse });
  }

  static set(name: FreskaCookie, value: string | object, expirationDays = EXPIRATION_DAYS) {
    cookies.set(name, value, {
      path: '/',
      expires: dateFromToday(expirationDays),
    });
  }

  static setSiteWide(name: FreskaCookie, value: string | object, expirationDays = EXPIRATION_DAYS) {
    cookies.set(name, value, {
      path: '/',
      expires: dateFromToday(expirationDays),
      domain: getTopDomain(),
    });
  }

  static saveParamsToCookies = () => {
    const qs = queryString.parse(window.location.search);
    const code = qs.c || qs.code;
    if (code) {
      // TODO: Rename to `freska_coupon_code` and sync checkout
      Cookies.setSiteWide('coupon-code', code as string, 7);
    }
  };

  static remove(name: FreskaCookie) {
    cookies.remove(name);
  }

  static removeSiteWide(name: FreskaCookie) {
    cookies.remove(name, {
      domain: getTopDomain(),
    });
  }
}
