import { camelCase } from 'lodash';

import type { EventFormData, FormField } from '../types/form-events';
import type { IdentifyUserTraits } from '../types/identify';

export const transformFormData = (fields: FormField[]): EventFormData =>
  fields.reduce((props, field) => ({ ...props, [camelCase(field.name)]: field.value }), {});

export const transformFormDataToUserTraits = (formData: EventFormData): IdentifyUserTraits => {
  return {
    firstName: formData.firstname,
    lastName: formData.lastname,
    email: formData.email,
    phone: formData.phone,
    address: {
      apartmentSize: formData.apartmentSize ?? formData.apartment_size ?? formData.apartmentSizeFreeForm,
      streetAddress: formData.address,
      postalCode: formData.postcode ?? formData.zip,
      city: formData.city,
      countryCode: formData.countrycode,
    },
  };
};
