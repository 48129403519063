import { siteConfigFI } from '../config/fi';
import { siteConfigNO } from '../config/no';
import { siteConfigSE } from '../config/se';

export type SiteConfig = {
  defaultSiteTitle: string;
  siteTitleSuffix: string;
  siteTitleSeparator: string;
  siteUrl: string;
  siteDescription: string;
  countryCode: string;
  defaultLocale: string;
  localesToPublish: string[];
  contentfulPublishIn: string;
  brandName: string;
  companyName: string;
  companyBusinessId: string;
  price: number;
  currency: string;
  features: {
    apartmentSizeInput: boolean;
  };
  phoneCallPrices?: {
    call: string;
    minute: string;
  };
  openingHours: {
    weekdays: string;
  };
  appStoreUrl: string;
  googlePlayUrl: string;
  accountFacebook: string;
  accountInstagram: string;
  accountTwitter: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string | null;
  linkedInUrl: string;
  whistleblowingUrl: string;
  facebookAppId: string | null;
  services: string[];
  cities: string[] | { name: string; urls: Record<string, string> }[];
  salesCheckoutRedirection: boolean;
  urls: {
    production: {
      website: string;
      checkout: string;
      d2d: string;
      tele: string;
    };
    staging: {
      website: string;
      checkout: string;
      d2d: string;
      tele: string;
    };
    development: {
      website: string;
      checkout: string;
      d2d: string;
      tele: string;
    };
  };
  slugs: Record<string, Record<string, string>>;
};

export const resolveSiteConfig = (): SiteConfig => {
  switch (process.env.GATSBY_SITE_COUNTRY) {
    case 'se':
      return siteConfigSE;
    case 'no':
      return siteConfigNO;
    case 'fi':
    default:
      return siteConfigFI;
  }
};

export const siteConfig = resolveSiteConfig();
