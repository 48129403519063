import React from 'react';

import { Radio as MuiRadio } from '@mui/material';
import type { RadioProps as MuiRadioProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RadioCheckedIcon } from './icons/RadioCheckedIcon';
import { RadioUncheckedIcon } from './icons/RadioUncheckedIcon';

export type RadioProps = MuiRadioProps;

export const Radio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <MuiRadio
      className={classes.root}
      color="primary"
      icon={<RadioUncheckedIcon />}
      checkedIcon={<RadioCheckedIcon />}
      {...props}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
  root: {
    color: theme.palette.primary.main,
  },
}));
